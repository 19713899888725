#root {
  width: 100%;
  position: relative;
  margin: 0;
}
.cl-code{
  font-family: -apple-system, BlinkMacSystemFont, 'Consolas';
}
.container-layout-base {
  position: relative;
}
.container-page-accueil {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
}
.fa-box {
  color: lightgray;
}
.container-resume {
  width: 100%;
  float: left;
}
.container-resume > h2 {
  margin-block-end: 23px;
  margin-block-start: 0;
}
.container-img {
  width: 250px;
  height: 180px;
  position: relative;
  margin-left: 60px;
}
.container-center{
  margin: 0 15%;
}
.superpose {
  position: absolute;
  width: auto;
  height: auto;
}
.page-content {
  margin-top: 3em;
}
.page-content > a {
  text-decoration: none;
  font-weight: bold;
  color: white;
}
.info-society{
  display: block;
  padding:0 2rem;
  font-size: small;
}
.info-society > span{
  margin:0 3rem 0 0;
}
.title {
  display: inline-flex;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 2.7rem;
}
.sub-title{
  color: #1890bf;
  margin: 0px;
}
.divider {
  width: 100px;
  height: 3px;
  margin: 15px 15px;
  background-color: #0c0633;
}
.padding-one {
  padding: 1rem;
}
.H-color{
  color: #1890bf;
}
.scale {
  display: inline;
}
.skill {
  box-shadow: 3px 3px 5px 0px #1890bf;
}
.pager {
  display: flex;
  display: -moz-box;
  display: -webkit-flex;
  flex-flow: row wrap;
  flex: 1 1 0;
  justify-content: space-between;
  align-items: flex-start;
}
.darksky {
  background: linear-gradient(
    240deg,
    rgb(24, 144, 191) 25%,
    #3d9bb4 60%,
    #276a9f 95%
  );
}
.greylight {
  background-color: #F3F3F3;
  padding: 30px;
}
.bluesky {
  box-shadow: 3px 3px 8px 0px #3d9bb4;
}
.whitesky {
  background-color: #ffffff;
  padding: 30px;
}
.shapesky {
  background: linear-gradient(120deg, #f0f1f3 15%, #c1c1c1 70%, #a3a5af 94%);
  padding: 30px;
}
.footer-logo{
  height: 20px;
  margin:13.5px 0;
}
.container-form {
  padding: 0;
}
#contactIn .container-form {
  display: flex;
  justify-content: center;
}
.surlign {
  font-weight: bold;
}
.text-scale{
  font-size: 500;
}
.btn-secondary {
  font-weight: bold;
  color: #0c0633;
}
.btn-secondary:hover {
  box-shadow: inset 11em 0em #0c0633;
  color: #fff;
}
.btn-content, .contact-btn {
  border-radius: 0.5rem;
  transition: all 0.5s ease 0s;
  background-color: #005A9C;
  border:0;
  font-size: 15px;
  color:#fff;
}
.contact-btn {
  margin: 5px 0;
  padding: 0.6rem 4rem;
}
.btn-content{
  width: 150px;
  height:40px;
}
.detail-info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(29, 28, 41, 0.6);
}
.portofolio-content {
  transition: margin 0.2s;
  height: auto;
}
.project {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(29, 28, 41, 0.6);
  height: 20rem;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}
.counter {
  margin-top: 3rem;
  margin-bottom: -1.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.fa-color {
  color: #1890bf;
}
.fa-color:hover {
color: #0c0633;
}

.fa-white {
  color: white;
}
/**/
.float-left {
  float: left;
}
.float-right {
  float: right;
  margin-top: 1.5rem;
}
.message-contact {
  color: #1890bf;
  border: darkgreen solid 0.2rem;
}
.error-contact {
  color: red;
  border: red solid 0.2rem;
}
/*Cloud Tags*/
.container-cloud {
  width: 65%;
  position: relative;
  flex: 0 0 41.666667%;
  display: flex;
}
.container-tag {
  cursor: pointer;
}
.tagger {
  margin: 0rem 0.3rem;
  vertical-align: middle;
  font-weight: bold;
  display: inline;
}
.tagger:hover,
.tagger:active,
.tagger:focus {
  transition-duration: 3s;
  transition-property: alternate;
  -ms-transform: scale3d(1, 2, 2);
  -moz-transform: scale3d(1, 2, 2);
  -webkit-transform: scale3d(1, 2, 2);
  -webkit-transition-duration: 3s;
  -webkit--property: alternate;
  transform: scale3d(1, 2, 2);
  transition-timing-function: ease-in;
  transition-delay: 3s;
}
/*Clients*/
.container-block {
  display: block;
}
.container-inline {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.logoType {
  object-fit: contain;
  max-width: -webkit-fill-available;
  width: 200px;
}
/*container shape*/
.section-shape {
  position: relative;
  width: 100%;
}
.section-shape span {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
}
.container-skill-box {
  border-radius: 0.5rem;
  height: 300px;
  background-color: white;
  padding: 0.5em 0;
  min-width: 100%;
  margin-bottom: 2rem;
}
.container-skill-box > .h3,h3{
  margin: 13.5px 0;
  font-family: inherit;
  font-size:22px;
}
/* Carousel*/
.team {
  background-color: white;
  box-sizing: border-box;
  padding: 0.5em 0.2em;
  min-width: auto;
  width: 100%;
  float: none;
  text-align: center;
  flex-wrap: nowrap;
  height: 350px;
}
.team-info {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: lightblue;
  height: 90px;
  border-radius: 0 0 5px 5px;
}
.team-info > a {
  color: #2293d7;
  text-decoration: none;
  margin-right: 4px;
}
.team-info > a:hover {
  color: white;
}
.team > span {
  color: dimgray;
  font-size: 16px;
}
.react-multi-carousel-track {
  list-style: none;
  padding: 10px 0 0 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  touch-action: manipulation;
  will-change: transform, transition;
  transition: "all 1s linear 0s";
}
.react-multi-carousel-list {
  overflow: hidden;
  justify-content: flex-start;
  width: 300px;
  min-height: 100%;
  align-items: center;
  transform: scale(1) perspective(1020px) rotateY(-11deg) rotateX(2deg)
    rotate(2deg);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1rem 2rem #3e42a9;
  z-index: 1;
  float: left;
  bottom: 3.5rem;
}
.react-multi-carousel-item {
  transform-style: preserve-3d;
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .react-multi-carousel-track {
    overflow: hidden !important;
  }
}
/*Team*/
.img-vib {
  vertical-align: middle;
  border-radius: 5px;
  box-shadow: 0 1rem 2rem rgb(102, 41, 41);
  display: flex;
  float: right;
  position: relative;
  background-position: 50%;
  background-size: cover;
  z-index: 1;
}
.img-vib > img {
  border-radius: 5px;
}
.container-blocnote {
  padding: 2rem;
  border-radius: 1em;
  min-height: 180px;
  min-width: 220px;
  margin-top: 1em;
  position: relative;
  z-index: 0;
  box-shadow: 0 1rem 1rem #185abc96;
}
.position-blocnote {
  float: right;
}
.position-adress {
  width: 50%;
  margin-top: 0;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  position: sticky;
}
.blocnote {
  border-radius: 1em;
  padding: 2.2rem;
  margin: initial;
  background-color: white;
}

/*Contact*/
.container-map {
  filter: grayscale(80%);
  -webkit-filter: grayscale(80%);
}
#geomap {
  padding-top: 25px;
  border: 0;
  height: 350px;
}
.banner {
  overflow: hidden;
  position: relative;
  height:350px;
  padding: 5% 0;
}
.banner > .h1,
h1,
.h2,
h2 {
  text-shadow: 0 1px 1px #276a9f;
  line-height: 1em;
}
.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  -moz-animation-duration: 0.6s;
  padding: 0 1rem 0 0;
}
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 200px, 0);
    -webkit-transform: translate3d(0, 200px, 0);
    -moz-transform: translate3d(0, 200px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.container-particle {
  position: absolute;
  left: 0;
  right: 0;
  height:350px;
}
.canvas-particle {
  z-index: 0;
}
.code-container {
  text-align: left;
  margin-top: 20px;
  padding: 0 40px;
}
.text-comment{
  color:gray;
}
.white-text > h4{
  color:#fff
}
.captcha {
  width: 100%;
}
.pos-left{
  margin-left:15px;
}
@media (min-width: 2000px) {
  .container {
    width: 60%;
  }
  .navbar-right {
    margin-right: 15px;
  }
  .container-skill-box {
    height: 100%;
  }
}
@media screen and (max-width:1200px){
  .container-skill-box{
    height:355px;
  }
}
@media screen and (max-width: 992px){
  .pager {
    margin-left: 0;
    flex-flow: column wrap;
    margin-bottom: 20px;
    align-items: inherit;
  }
  .row {
    margin: 0;
  }
  .container-img {
    margin-left: 35%;
  }
  .container-blocnote {
    left: 00px;
  }
  .react-multi-carousel-list {
    top: 00px;
    margin-top: 1em;
    transform: none;
    left: calc(100% / 3.3);
  }
  .captcha {
    margin: 0 15px;
  }
  #geomap {
    width: 100%;
  }
  .container-skill-box {
    min-height: 100%;
    height: auto;
  }
  .position-adress {
    width: inherit;
  }
  .code-container{
    display: none;
  }
}
@media screen and (max-width: 780px) and (min-width: 581px) {
  
  .row {
    margin: 0;
    width: 95%;
  }
  .container-img {
    margin-left: 30%;
  }
  .react-multi-carousel-list {
    left: calc(100% / 4);
  }
  .navbar-header {
    margin-left: 15px;
  }
   .position-adress {
    width: inherit;
  }
  .container-form {
    display: flex;
    flex-direction: column;
  }
  .navbar-logo-img {
    margin-left: 35px;
  }
  .navbar-toggle {
    margin-bottom: 0;
    margin-top: 0.5rem;
 }
 .navbar-brand {
  float: left;
  padding: 1rem 0;
 }

}
@media screen and (max-width: 580px) {
  .container{
    padding-left:0
  }
  .h1,h1 {
    font-size: 35px;
  }
  .h2,h2 {
    font-size: 30px;
  }
  .h3,h3 {
    font-size: 23px;
  }
   .row {
    margin: 0;
  }
  .section-container{
    padding: 0 10px;
  }
  .pager {
    margin-left: 0;
    flex-flow: column wrap;
    margin-bottom: 20px;
    display: inherit;
  }
  .whitesky > .container {
    padding: 0;
  }
  .container-img {
    margin-left: 20%;
  }
  .container-blocnote {
    left: 00px;
    margin-top: 1.5em;
  }
  .react-multi-carousel-list {
    top: 00px;
    margin: 1em 3.5rem;
    transform: none;
  }
  .btn-content {
    margin-top: 1em;
  }
  .captcha {
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .float-right {
    float: none;
  }
  .float-left {
    float: none;
  }
  .container-map {
    display: table-row;
  }
  .container-skill-box {
    height: 100%;
  }
  .position-adress {
    width: inherit;
  }
  .container-form {
    display: flex;
    flex-direction: column;
  }
  .navbar-toggle {
   margin-bottom: 0;
   margin-top:16px
}
.navbar-logo-img {
  margin-left: 20px;
  margin-top: 5px;
}
.info-society{
   display: none;
}
.code-container{
  display: none;
}
 .title{
  flex-direction:column;
 }
 .sub-title{
  padding-left: 1.5rem;
}
}
@media screen and (max-width: 490px) {
  .react-multi-carousel-list {
    left: inherit;
    margin: 1.5em 0;
  }
  .container-img {
    margin: 0px calc(100% / 6.5);
  }
  .navbar-logo-img {
    margin-right: 25px;
    margin-top: 0;
    height:30px;
  }
  .navbar-toggle, .navbar-brand{
    margin-top: 15px;
  }
  .h1,h1 {
    font-size: 35px;
  }
  .h2,h2 {
    font-size: 30px;
  }
  .h3,h3 {
    font-size: 23px;
  }
  .h4,h4 {
    font-size: 17px;
  }
  .container-inline{
    flex-flow: column wrap;
    align-items: center;
    margin-bottom: 2rem;
   }
   .counter{
     margin-bottom: inherit;
   }
}
@media screen and (max-width: 360px) {
  .row {
    margin: initial;
  }
  .counter {
    align-items: center;
    place-content: normal;
    flex-flow: column;
  }
  .img-vib {
    display: flex;
    display: -webkit-flex;
    left: calc(100% / 7.2);
  }
  .react-multi-carousel-list {
    left: calc(100% / -7.5);
    margin: 1em 0;
  }
  .container-img {
    float: left;
    margin-left: auto;
    left: calc(100% / 8.5);
  }
  .navbar-logo-img {
    height:30px;
  }
  .nav > li > a {
    font-size: 15px;
  }
}
@media screen and (max-width: 320px) {
  .col-md-6,
  .col-md-4 {
    padding: 0em;
  }
  .captcha {
    margin-left: -20px;
  }
  .counter > .col-md-4 {
    margin-left: 15px;
    margin-right: 15px;
    flex-flow: column;
  }
  .contact-btn{
    padding: 0.6rem 2rem;
    font-size:14px;
  }
}
.fa-content {
  flex-shrink: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fa-content::after {
  content: " ";
  overflow: hidden;
}
.adress-right {
  float: right;
}
.cookie-button {
  background: "#276A9F";
  color: "#fff";
  font-size: 13px;
}
